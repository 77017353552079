import {
  Badge,
  Button,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { ActionButton, SaveButton } from "components/Common/Button";
import SideMemoPanel from "components/Common/SideMemoPanel";
import "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useRecoilState } from "recoil";
import { MyRoutes } from "routes/Routes";
import { theme } from "style/theme";
import { ErrorHandler, ProjectApi, UserApi } from "system/ApiService";
import { SubProjectAtom, TranslateProjectAtom } from "system/atom";
import { useLoadingDispatch } from "system/LoadingContext";
import {
  ImageTextDataManual,
  ImageTextTargetManual,
  INIT_PROJECT_COMPLETE_HISTORY,
  INIT_SELECTED_ITEM,
  INIT_SELECTED_SENTENCE,
  Memo,
  ProjectCompleteHistory,
  SelectedItem,
  SelectedSentence,
  SourceTargetMatching,
} from "system/types";
import { useUserState } from "system/UserContext";
import TranslationGuide from "./TranslationGuide";
import TranslationInputManual from "./TranslationInputManual";

import ChatModalView from "components/Common/ChatModalView";
import SourceTargetMatchingModalContent from "components/Common/SourceTargetMatchingModalContent";
import jwt_decode from "jwt-decode";

interface Params {
  translate_project_code: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    width100: {
      width: "100%",
      padding: "20px",
    },
    center: {
      padding: "20px",
      // 내부 요소를 중앙 정렬
      display: "flex",
      justifyContent: "center",
    },
    pictureLeft: {
      width: "100%",
      padding: "4px",
    },
    imageSize: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxHeight: "70vh",
      "& > *": {
        backgroundColor: "#DCDFE3",
      },
    },
    pictureRight: {
      overflowY: "scroll",
      maxHeight: "70vh",
      padding: "4px",
    },
    sideGuide: {
      // overflowY: "scroll",
      maxHeight: "70vh",
    },
    textArea: {
      width: "100%",
    },
    info: {
      marginTop: "10%",
      marginBottom: "10%",
      textAlign: "center",
    },
    commentArea: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "white",
      border: "1px solid #ccc",
      textAlign: "center",
    },
    textField: {
      marginTop: "20px",
    },
    root: {
      display: "flex",
      alignItems: "flex-end",
    },
    roots: {
      display: "flex",
      alignItems: "flex-start",
    },
    root2: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      "& > *": {
        marginBottom: theme.spacing(2), // 아이템들에 하단 여백 부여
        minHeight: "50px", // 최소 높이 설정
        overflow: "auto", // 내용이 너무 많을 때 스크롤 허용
      },
      "@media (max-width: 600px)": {
        // 미디어 쿼리를 사용하여 모바일 화면에 대응
        flexDirection: "column",
        alignItems: "stretch", // 모바일 화면에서는 아이템들을 늘림
      },
    },

    textFieldContainer: {
      padding: theme.spacing(0.5),
    },

    rightContainer: {
      overflow: "auto", // 내용이 너무 많을 때 스크롤 허용
      backgroundColor: "#f0f2f5",
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start",
      // justifyContent: "space-around",
      padding: theme.spacing(1),
      height: "100%",
      maxHeight: "70vh",
    },

    guideImages: {
      height: "1vw",
    },
    underlined: {
      borderBottom: "2px solid gray",

      fontWeight: "bold",
    },
    rootTab: {
      // display: "flex",
      width: "100%",
      // flexGrow: 1, // 부모 컨테이너를 꽉 채우도록 설정
      backgroundColor: "#eee", // 배경색 설정, 필요에 따라 변경
    },
    tabs: {
      width: "100%", // 탭의 너비를 100%로 설정
    },
    flexContainer: {
      width: "100%", // flexContainer의 너비를 100%로 설정
    },
    tabRoot: {
      minWidth: "0", // min-width 스타일을 오버라이드
      width: "50%", // 각 탭의 너비를 부모의 50%로 설정 (예시: 2개의 탭일 경우)
    },
    loadingWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(2, 0),
    },
    progressWrapper: {
      width: "100%",
      maxWidth: "400px",
      marginTop: theme.spacing(2),
    },
    messageBox: {
      textAlign: "center",
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
      fontWeight: 500,
    },
    backdrop: {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: theme.zIndex.drawer + 1,
    },
    linearProgress: {
      height: 8,
      borderRadius: 4,
      "& .MuiLinearProgress-bar": {
        borderRadius: 4,
      },
    },

    //#region 메모 채팅형식 표시 관련
    dialog: {
      position: "fixed",
      right: 0,
      margin: 0,
      height: "100%",
      maxWidth: "400px",
      "& .MuiDialog-paper": {
        margin: 0,
        width: "100%",
        maxWidth: "400px",
      },
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    closeButton: {
      color: theme.palette.primary.contrastText,
    },
    messageContainer: {
      padding: theme.spacing(2),
      height: "100%",
      overflowY: "auto",
    },
    message: {
      marginBottom: theme.spacing(2),
      maxWidth: "80%",
    },
    adminMessage: {
      marginLeft: "auto",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
    },
    translatorMessage: {
      marginRight: "auto",
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
    },
    timestamp: {
      fontSize: "0.75rem",
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(0.5),
    },

    chatModalView: {
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      // flexDirection: "row",
    },
    //#endregion
  })
);

interface Size {
  width: number;
  height: number;
}

export function TranslatorManualComponent() {
  const { t } = useTranslation();
  const loading = useLoadingDispatch();

  let { translate_project_code } = useParams<Params>();
  let HasCommentTranslator = false;
  let HasCommentAdmin = false;

  const classes = useStyles();
  const [, setHasComments] = useState<boolean>(false);
  const [commentCount, setCommentCount] = useState<number>(0);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const user = useUserState();
  const [pageNum, setPageNum] = useState<number>(1);
  const [wholePageNum, setWholePageNum] = useState<number>(0);
  // const [translateProject, setTranslateProject] = useState<TranslateProject>(_trans ? _trans : INIT_TRANSLATE_PROJECT);
  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);
  const [translateProject, setTranslateProject] = useRecoilState(TranslateProjectAtom);
  const [imageTextTargetList, setImageTextTargetList] = useState<ImageTextTargetManual[]>([]);
  // const [isClosed, setIsClosed] = useState(translateProject.is_closed ?? false);
  // const [isFinished, setIsFinished] = useState(translateProject.project_status === "Completed");

  const [imageSize, setImageSize] = useState<Size>({ width: 0, height: 0 });
  const [, setIsEdited] = useState<boolean>(false);
  const [, setInputValue] = useState("1");
  const [, setWrapperSize] = useState<Size>({ width: 0, height: 0 });

  // 번역 화면에서 선택한 컴포넌트
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(INIT_SELECTED_ITEM);
  // Guide에서 선택한 문장
  const [selectedSentence, setSelectedSentence] = useState<SelectedSentence>(INIT_SELECTED_SENTENCE);

  const [isVisible, setIsVisible] = useState(false);

  // TODO 무엇을 위한 Visibility인지 확인 필요
  const handleToggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  // Summary Modal의 표시 여부
  const [isModalSummaryOpen, setIsModalSummaryOpen] = useState<boolean>(false);

  // #region 페이지용 번호 ref
  const pageNumberRef = useRef<HTMLInputElement>(null);
  // #endregion

  interface PageInfo {
    page: number;
  }

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        setWrapperSize({
          width: wrapperRef.current.offsetWidth,
          height: wrapperRef.current.offsetHeight,
        });
      }
    };
    // 초기 렌더링 후에 크기를 계산합니다.
    window.setTimeout(handleResize, 0);

    // 창 크기가 변경될 때마다 크기를 다시 계산합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapperRef]);

  //#region Pagememo 관련
  const [commentTranslator, setCommentTranslator] = useState("");
  const [commentAdmin, setCommentAdmin] = useState("");

  const handleSaveCommentTranslatorClick = () => {
    ProjectApi.addPagememo(translateProject.id ? translateProject.id : -1, pageNum, commentTranslator)
      .then((res) => {
        alert("Successfully sent the memo.");
        handleTrigger();
      })
      .catch((e) => {
        console.log(e);
        alert("Failed to send the memo. Please try logging in again.\nIf the issue persists, contact headquarters.");
      })
      .finally(() => {});
  };

  const handleSaveCommentAdminClick = () => {
    ProjectApi.addPagememo(translateProject.id ? translateProject.id : -1, pageNum, commentAdmin)
      .then((res) => {
        alert("Successfully sent the memo.");
        handleTrigger();
      })
      .catch((e) => {
        console.log(e);
        alert("Failed to send the memo. Please try logging in again.\nIf the issue persists, contact headquarters.");
      })
      .finally(() => {});
  };

  // 페이지 메모 조회
  useEffect(() => {
    if (translateProject.id && translateProject.id > 0) {
      ProjectApi.getPagememo(translateProject.id, pageNum)
        .then((res) => {
          // 1. Filter data where memo_disable is false
          const enabledMemos = res.data.filter((memo: Memo) => !memo.memo_disable);

          // 2. Group the filtered data by memo_type
          const groupedMemos: { [key: number]: Memo[] } = enabledMemos.reduce((acc: any, memo: Memo) => {
            (acc[memo.memo_type] = acc[memo.memo_type] || []).push(memo);
            return acc;
          }, {});

          // 3. From each group, get the item with the latest time_created
          const latestMemos = Object.values(groupedMemos).map((memos: Memo[]) => {
            return memos.reduce((latest: Memo, current: Memo) => {
              return new Date(current.time_created) > new Date(latest.time_created) ? current : latest;
            });
          });

          HasCommentTranslator = false;
          HasCommentAdmin = false;

          latestMemos.forEach((memo: Memo) => {
            if (memo.memo_type === 1) {
              setCommentAdmin(() => memo.memo_contents);
              HasCommentAdmin = true;
              console.log("admin", pageNum);
            } else if (memo.memo_type === 2) {
              setCommentTranslator(() => memo.memo_contents);
              HasCommentTranslator = true;
              console.log("translator", pageNum);
            }
          });

          if (!HasCommentAdmin) setCommentAdmin("");
          if (!HasCommentTranslator) setCommentTranslator("");

          setHasComments(HasCommentAdmin || HasCommentTranslator); // 코멘트가 있는지 여부를 확인하는 변수
          setCommentCount((HasCommentAdmin ? 1 : 0) + (HasCommentTranslator ? 1 : 0)); // 코멘트의 수

          setIsVisible(() => {
            return HasCommentAdmin || HasCommentTranslator;
          });
        })
        .catch((error) => {
          console.error("Error occurred during promise execution:", error);
        })
        .finally(() => {});
    }
  }, [pageNum, translateProject.id]);
  //#endregion

  // const loading = useLoadingDispatch();

  useEffect(() => {
    const sourceLangCode = subProject.source_lang_code;
    // 일본, 중국은 KO, 나머지 국가는 EN_EN을 원문 언어로 한다.
    //translateProject.target_lang_code === "JA" ||
    // translateProject.target_lang_code === "CN" ||
    // ** todo: 영문검토시 원문이 EN_EN으로 보여야하는 이슈 존재
    // translateProject.target_lang_code === "EN_EN" ? "KO" : "EN_EN";

    // Translate Project가 속한 Sub Project의 원문 페이지 갯수 조회
    if (translateProject.sub_project_id) {
      ProjectApi.getMaxSourcePageNumber(translateProject.sub_project_id + "", sourceLangCode)
        .then((res) => {
          setWholePageNum(res.data.max_page_no);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, [translateProject.sub_project_id]);

  const onLoad = React.useCallback((page: number) => {
    let translate_project_id =
      translate_project_code && translate_project_code !== "" ? translate_project_code : translateProject.id + "";

    ProjectApi.GetTranslateProjectDetail(translate_project_id)
      .then((res) => {
        let sourceLang = subProject.source_lang_code;

        ProjectApi.GetListSourceTargetAllInOne(res.data.id, sourceLang, res.data.target_lang_code, page)
          .then((ListSourceTargetRes) => {
            setTranslateProject(res.data);
            // 번역 데이터 세팅
            setImageTextTargetList(ListSourceTargetRes.data[0].a_image_and_multi_texts);
          })
          .catch((e) => {
            let msg = ErrorHandler(e);
            console.log(e);
          })
          .finally(() => {});
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        alert(msg);
        console.log(msg);
      });
  }, []);

  // 번역 마감 이력 조회
  useEffect(() => {
    // 현재 마감되어있는지 여부확인
    if (!isFinished) {
      return;
    }
    ProjectApi.GetTranslateProjectCompleteHistory(translateProject.id ? translateProject.id : -1)
      .then((res) => {
        if (!res.data || res.data.length <= 0) {
          return;
        }
        // 가장 최근에 생성된 complete_type이 3인 데이터 찾기
        const mostRecentDataWithType3 = res.data.reduce(
          (prevData: ProjectCompleteHistory, currentData: ProjectCompleteHistory) => {
            if (!prevData) {
              return currentData;
            }
            const prevDataTime = new Date(prevData.time_created).getTime();
            const currentDataTime = new Date(currentData.time_created).getTime();

            if (currentDataTime > prevDataTime && currentData.complete_type === 3) {
              return currentData;
            } else {
              return prevData;
            }
          },
          null
        );

        // 가장 최근에 생성된 complete_type이 3인 데이터 찾기
        const mostRecentDataWithType2 = res.data.reduce(
          (prevData: ProjectCompleteHistory, currentData: ProjectCompleteHistory) => {
            if (!prevData) {
              return currentData;
            }
            const prevDataTime = new Date(prevData.time_created).getTime();
            const currentDataTime = new Date(currentData.time_created).getTime();

            if (currentDataTime > prevDataTime && currentData.complete_type === 2) {
              return currentData;
            } else {
              return prevData;
            }
          },
          null
        );

        if (mostRecentDataWithType3 && mostRecentDataWithType3.complete_type) {
          setProjectCompleteHistory(mostRecentDataWithType3.complete_type === 3 ? mostRecentDataWithType3 : null);
        }

        if (mostRecentDataWithType2 && mostRecentDataWithType2.complete_type) {
          setProjectReOpenHistory(mostRecentDataWithType2.complete_type === 2 ? mostRecentDataWithType2 : null);
        }
        const historyWithType3 = res.data.filter((item: ProjectCompleteHistory) => item.complete_type === 3);
        setProjectCompleteHistory(historyWithType3);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [pageNum, translateProject.id]);

  // 초기 진입여부 확인
  const location = useLocation();
  useEffect(() => {
    let pageInfo = null;
    if (location.state && (location.state as PageInfo).page >= 0) {
      pageInfo = location.state;
      let page = (pageInfo as PageInfo).page;

      setPageNum(() => page);
      onLoad(page);
      setImageTextTargetList([]);
      setSelectedItem(INIT_SELECTED_ITEM);

      location.state = { ...location, state: { page: -1 } };
    } else {
      onLoad(pageNum);
    }
  }, []);

  //#region 페이지 이동 관련 함수
  const changePageNumber = (page: number) => {
    // 페이지 이동 경고용 구분자
    let isBlock = false;
    // 번역 입력칸에 글자가 있는지 확인
    imageTextTargetList.forEach((element: ImageTextTargetManual, index: number) => {
      element.image_text_data.map((subElement: ImageTextDataManual, subIndex: number) => {
        if (imageTextTargetList[index].image_text_data[subIndex].is_onWrite) {
          isBlock = true;
        }
      });
    });

    if (isBlock && groupRole !== 2) {
      if (
        window.confirm(
          "Any unsaved content will be lost. Still want to move?\nPlease check if all activated buttons have been pressed."
        )
      ) {
        setPageNum(() => page);
        onLoad(page);
        setImageTextTargetList([]);
        setSelectedItem(INIT_SELECTED_ITEM);
      }
    } else {
      setPageNum(() => page);
      onLoad(page);
      setImageTextTargetList([]);
      setSelectedItem(INIT_SELECTED_ITEM);
    }
  };

  // PageNumberRef 값으로 페이지 이동
  const handleGoClick = () => {
    if (!pageNumberRef.current) return;
    const newPage = Number(pageNumberRef.current?.value);

    if (!isNaN(newPage) && newPage >= 1 && newPage <= wholePageNum) {
      changePageNumber(newPage);
    } else {
      alert("Invalid page number");
    }
  };
  const onPageNumChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    window.onbeforeunload = function (event: any) {
      event.preventDefault();
    };
    changePageNumber(page);
  };
  //#endregion

  const onWriteUnit = (imageTextData: ImageTextDataManual, index: number, subIndex: number) => {
    imageTextTargetList[index].image_text_data[subIndex].is_onWrite = true;
    setImageTextTargetList(imageTextTargetList);
  };

  const onSaveUnit = (imageTextData: ImageTextDataManual, index: number, subIndex: number, isSaved: boolean) => {
    // 번역과제 정보가 없다면 skip
    if (!translateProject.id) {
      return;
    }

    // targettext 있는지 먼저 확인
    ProjectApi.GetTargetTextBySourceId(imageTextData.source_id, translateProject.target_lang_code, translateProject.id)
      .then((res) => {
        if (imageTextData.target_id || res.data.id) {
          // targettext가 존재하면 Update
          imageTextData.target_id = res.data.id;
          ProjectApi.UpdateTargetTextBySourceId(imageTextData, translateProject, user, "image")
            .then((res) => {
              console.log(res, "success");
              imageTextTargetList[index].image_text_data[subIndex].is_onWrite = false;
              setImageTextTargetList(imageTextTargetList);
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        } else {
          // targettext가 존재하지 않는다면 Insert
          ProjectApi.AddTargetText(imageTextData, translateProject, user, "image")
            .then((res) => {
              console.log(res);
              // imageTextTargetList[index].image_text_data[subIndex].is_basetext = false;
              imageTextTargetList[index].image_text_data[subIndex].is_onWrite = false;
              setImageTextTargetList(imageTextTargetList);
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterUntranslated = (data: SourceTargetMatching[]) => {
    return data.filter((item) => item.is_translated === false);
  };

  //#region 프로젝트 마감/마감취소 관련 함수
  // 마감 이후 프로젝트 관리화면으로 이동하기 위함
  const history = useHistory();

  const [isClosed, setIsClosed] = useState(translateProject.is_closed ?? false);
  const [isFinished, setIsFinished] = useState(translateProject.project_status === "Completed");

  // 프로젝트 마감 정보
  const [projectCompleteHistory, setProjectCompleteHistory] =
    useState<ProjectCompleteHistory>(INIT_PROJECT_COMPLETE_HISTORY);

  // 프로젝트 재오픈 정보
  const [projectReOpenHistory, setProjectReOpenHistory] =
    useState<ProjectCompleteHistory>(INIT_PROJECT_COMPLETE_HISTORY);

  // 번역가 마감
  const onCloseProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-close"))) {
      ProjectApi.getTranslateCompareSourceTargetByProjectCode(id)
        .then((res) => {
          const untranslatedData = filterUntranslated(res.data);
          if (untranslatedData && untranslatedData.length > 0) {
            let noList: number[] = [];
            untranslatedData.map((item: SourceTargetMatching) => {
              noList.push(item.page_no);
            });

            const uniqueNoList: Set<number> = new Set(noList);
            alert("Translation not completed on the following pages.\n" + Array.from(uniqueNoList).join(", "));
          } else {
            ProjectApi.CloseTranslateProject(id)
              .then(() => {
                history.push(MyRoutes.translatorProject);
                console.log("close success");
              })
              .catch((e) => {
                let msg = ErrorHandler(e);
                console.log(e);
                setIsClosed(true);
                if (e.response.status === 501) {
                  window.alert(e.response.data.message);
                } else {
                  console.log(e.response);
                }
              })
              .finally(() => {});
          }
        })
        .catch((e) => {});
    }
  };

  // 관리자(최종) 마감
  const onFinishProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-finish"))) {
      loading({ type: "LOADING" });
      ProjectApi.FinishTranslateProject(id)
        .then(() => {
          history.push(MyRoutes.projectManagementComplete);
          console.log("finish success");
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
          setIsFinished(true);
          alert("An error has occurred.\nPlease contact the Infrastructure Development Department at headquarters.");
        })
        .finally(() => {
          loading({ type: "COMPLETE" });
        });
    }
  };

  // 마감취소
  const onReopenProject = (id: number) => {
    if (window.confirm("" + t("dialogue-confirm-reopen"))) {
      ProjectApi.ReopenProject(id)
        .then(() => {
          console.log("reopen success");
          history.push(MyRoutes.projectManagementComplete);
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
          alert("An error has occurred.\nPlease contact the Infrastructure Development Department at headquarters.");
        });
    }
  };
  //#endregion

  // [번역가] 코멘트 작성
  const handleCommentTranslatorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentTranslator(event.target.value);
  };
  // [관리자] 코멘트 작성
  const handleCommentAdminChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentAdmin(event.target.value);
  };

  //#region `최종 프로젝트 재오픈`으로 인해 변경된 번역문장인지 여부 파악

  const UPDATE_BUFFER_TIME = 5 * 60 * 1000;
  const checkTrue = (index: number, subIndex: number) => {
    let translateInfotemp = imageTextTargetList[index].image_text_data[subIndex];
    let basetexttemp = translateInfotemp.basetext;
    let basetext_historytemp = translateInfotemp.basetext_history;

    if (index >= 0 && subIndex >= 0) {
      if (basetexttemp.length > 0) {
        if (basetext_historytemp.length > 0)
          if (basetext_historytemp[0].history_data.length > 0) {
            if (!projectReOpenHistory) {
              // Basetext 변경 여부는 reopen이력을 기준으로 판단
              console.log(index, subIndex, basetexttemp, basetext_historytemp, "no reopen data");
            }
          }
      }
    }

    if (!projectCompleteHistory || !projectReOpenHistory || projectCompleteHistory.complete_type !== 3) {
      return false;
    }
    let translateInfo = imageTextTargetList[index].image_text_data[subIndex];
    let basetext = translateInfo.basetext;
    let basetext_history = translateInfo.basetext_history;
    let target_sentence = translateInfo.target_sentence;
    if (index >= 0 && subIndex >= 0) {
      if (basetext.length > 0) {
        if (basetext_history.length > 0)
          if (basetext_history[0].history_data.length > 0) {
            // 재오픈 시 사용, history가 없는 데이터는 의미 없는 데이터
            // Basetext와 History가 함께 존재

            // 데이터 결합
            // const combinedData = createCombinedData(index, subIndex);
            // 프로젝트 종료시점으로 가장 가까운 1개 추출
            const latestEntries = basetext_history[0].history_data
              .filter((data) => {
                const dataTime = new Date(data.time_created).getTime();
                // FIXME : Backend에서 History데이터에 Basetext의 생성 시간을 넣을때까지 유지
                // const projectTime = new Date(projectReOpenHistory.time_created).getTime();
                const projectTime = new Date(projectCompleteHistory.time_created).getTime();
                // FIXME dataTime과 projectTime의 차이가 10분 이내의 데이터만 추출
                return dataTime < projectTime && projectTime - dataTime < UPDATE_BUFFER_TIME;
              })
              .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime())
              .slice(0, 1);
            return (
              latestEntries && latestEntries.length > 0 && latestEntries[0].history_managed_text !== target_sentence
            );
          }
      }
    }

    // todo : 위 조건에 걸리지 않는 경우 check
  };
  //#endregion

  // 현재 선택된 번역문장
  const handleItemClick = (
    newData: ImageTextDataManual,
    index: number,
    subIndex: number,
    lastTwoSentences: string[],
    projectReOpenHistory: ProjectCompleteHistory,
    isClosed: boolean | undefined
  ) => {
    const selectedItem = {
      newData,
      index,
      subIndex,
      lastTwoSentences,
      projectReOpenHistory,
      isClosed: isClosed ?? false, // isClosed가 undefined일 경우 false로 처리
      isFinished: isFinished,
    };

    setSelectedItem(selectedItem);
  };

  // #region ResizeObserver 객체 생성

  // Ref 객체 생성 (HTMLDivElement 타입을 명시)
  const elementRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);

  // 상태를 사용하여 요소의 크기를 저장 (Size 인터페이스 사용)
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  //#region TODO Archive 정리필요
  useEffect(() => {
    const observeTarget = elementRef.current;
    if (!observeTarget) return;

    // ResizeObserver 콜백 함수 정의
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });

    // 요소 관찰 시작
    resizeObserver.observe(observeTarget);

    // 컴포넌트 언마운트 시, 관찰 중단
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const observeTarget = imageRef.current;
    if (!observeTarget) return;

    // ResizeObserver 콜백 함수 정의
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setImageSize({ width, height });
      }
    });

    // 요소 관찰 시작
    resizeObserver.observe(observeTarget);

    // 컴포넌트 언마운트 시, 관찰 중단
    return () => resizeObserver.disconnect();
  }, []);
  //#endregion

  //#region 이미지/요소 로딩 완료시, 정보 업데이트
  const [imageLoaded, setImageLoaded] = useState(false);
  const [elementLoaded, setElementLoaded] = useState(false);
  useEffect(() => {
    if (imageRef.current) {
      const { offsetWidth, offsetHeight } = imageRef.current;
      // 초기 크기 설정
      setImageSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [imageLoaded]);

  useEffect(() => {
    if (elementRef.current) {
      const { offsetWidth, offsetHeight } = elementRef.current;
      // 초기 크기 설정
      setSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [elementLoaded]);
  //#endregion

  const [value, setValue] = React.useState(2);

  // 탭 변경 이벤트 핸들러
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  const [trigger, setTrigger] = useState(false);
  const handleTrigger = () => {
    setTrigger(true);
  };
  const resetTrigger = () => {
    setTrigger(false);
  };

  // #region User의 권한 확인
  const [groupRole, setGroupRole] = useState<number>(0); // 시스템관리자 : 1, 본사 인원 : 2
  const getUserRole = React.useCallback(() => {
    const decoded: any = jwt_decode(UserApi.GetToken());
    setGroupRole(decoded.sub.group_role[0]);
  }, []);

  useEffect(() => {
    getUserRole();
  }, [getUserRole]);
  // #endregion

  interface Message {
    id: number;
    role: "Admin" | "Translator";
    content: string;
    timestamp: string;
  }
  const messages: Message[] = [
    {
      id: 1,
      role: "Translator",
      content: "번역이 완료되었습니다.1",
      timestamp: "2024-03-27T10:30:00",
    },
    {
      id: 2,
      role: "Admin",
      content: "확인했습니다. 감사합니다.11",
      timestamp: "2024-03-27T10:35:00",
    },
    {
      id: 3,
      role: "Translator",
      content: "번역이 완료되었습니다.111",
      timestamp: "2024-03-27T10:30:00",
    },
    {
      id: 4,
      role: "Admin",
      content: "확인했습니다. 감사합니다.1111",
      timestamp: "2024-03-27T10:35:00",
    },
    {
      id: 5,
      role: "Translator",
      content: "번역이 완료되었습니다.11111",
      timestamp: "2024-03-27T10:30:00",
    },
    {
      id: 6,
      role: "Admin",
      content: "확인했습니다. 감사합니다.",
      timestamp: "2024-03-27T10:35:00",
    },
    {
      id: 7,
      role: "Translator",
      content: "번역이 완료되었습니다.",
      timestamp: "2024-03-27T10:30:00",
    },
    {
      id: 8,
      role: "Admin",
      content: "확인했습니다. 감사합니다.",
      timestamp: "2024-03-27T10:35:00",
    },
    {
      id: 9,
      role: "Translator",
      content: "번역이 완료되었습니다.",
      timestamp: "2024-03-27T10:30:00",
    },
    {
      id: 10,
      role: "Admin",
      content: "확인했습니다. 감사합니다.1111111111",
      timestamp: "2024-03-27T10:35:00",
    },
  ];

  return (
    <Grid container spacing={1}>
      {groupRole !== 2 && (
        <Grid item xs={12} container justifyContent="flex-end">
          {!translateProject?.is_closed ? (
            // 번역가 마감 버튼
            <SaveButton
              onClick={() => {
                onCloseProject(parseInt(translate_project_code));
              }}
              disabled={translateProject.is_closed || isClosed}
              title={
                "Final Submission button:\nIf you clicked on all 'Finish/Check Button', and submitted all translated sentences,\nplease click on 'Final Submission' button for administrator's final approval."
              }
            >
              {t("final-submission")}
            </SaveButton>
          ) : (
            // 관리자 마감취소버튼 & 관리자 마감버튼
            <>
              <div>
                <SaveButton
                  onClick={() => {
                    let translate_project_id =
                      translate_project_code && translate_project_code !== ""
                        ? translate_project_code
                        : translateProject?.id + "";
                    onReopenProject(parseInt(translate_project_id));
                  }}
                  color="secondary"
                  // 번역가 마감 상태일때만 re-open 가능하도록 변경
                  disabled={!translateProject.is_closed}
                  style={{ marginRight: "28px" }}
                >
                  Reopen project
                </SaveButton>
                <SaveButton
                  onClick={() => {
                    let translate_project_id =
                      translate_project_code && translate_project_code !== ""
                        ? translate_project_code
                        : translateProject?.id + "";
                    onFinishProject(parseInt(translate_project_id));
                  }}
                  disabled={translateProject.project_status === "Completed" || isFinished}
                >
                  final submission (admin)
                </SaveButton>
              </div>
            </>
          )}
        </Grid>
      )}
      {/* 상단 기능 (페이지네이션, 요약, 전체 메모, 메모 숨기기) */}
      <Grid item xs={12} container justifyContent="space-between">
        <div className={classes.root}>
          <TextField
            label="Go to page"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleGoClick();
                handleTabChange(e, 2);
              }
            }}
            type="number"
            inputRef={pageNumberRef}
          />
          <Button
            onClick={(e) => {
              handleGoClick();
              handleTabChange(e, 2);
            }}
          >
            Go
          </Button>
        </div>

        <div className={classes.root}>
          <Pagination
            count={wholePageNum}
            defaultValue={1}
            siblingCount={3}
            page={pageNum}
            showFirstButton
            showLastButton
            size={"large"}
            color="primary"
            onChange={
              // handleInputChange 실행 이후에 handletabchange 실행
              (e: React.ChangeEvent<unknown>, page: number) => {
                onPageNumChangePagination(e, page);

                // 페이지 전환 시, 가이드 탭으로 전환
                handleTabChange(e, 2);
              }
            }
          ></Pagination>
        </div>

        <div className={classes.root}>
          <ActionButton
            style={{
              backgroundColor: "#dcdcdc",
              marginRight: "24px",
              color: "black",
            }}
            size="small"
            variant="contained"
            onClick={(event) => {
              event.stopPropagation();
              setIsModalSummaryOpen(true);
            }}
            title={"Clicking on PREVIEW shows project’s full translations."}
          >
            SUMMARY
          </ActionButton>
          {translateProject && translateProject.id && translateProject.id > 0 && (
            <SideMemoPanel
              key={translateProject?.id}
              translate_project_id={translateProject.id}
              translate_project={translateProject}
              _changePageNumber={changePageNumber}
              // trigger={trigger}
              // _resetTrigger={resetTrigger}
            ></SideMemoPanel>
          )}
          <Button
            onClick={handleToggleVisibility}
            variant="contained"
            style={{
              // backgroundColor: hasComments ? "lightgray" : PRIMARY, // 코멘트가 있으면 배경색을 변경
              backgroundColor: "lightgray", // TODO : 코멘트 갯수와 상관없이 회색으로 표시
              // color: hasComments ? "black" : "white",
              color: "black",
            }}
            size="small"
            title={
              "Send Memo Button:\nIf you have any suggestions/questions on the page you are in, click on 'SEND MEMO' to convey to the headquarters. \nAdministrator will respond by using the 'SEND MEMO'."
            }
          >
            <Badge
              color="error" // 빨간색으로 설정
              variant="dot" // 점으로 표시
              invisible={commentCount <= 0} // commentCount가 0 이하일 때는 숨김
              anchorOrigin={{
                vertical: "top", // 상단에 위치
                horizontal: "left", // 왼쪽에 위치
              }}
            >
              send memo
            </Badge>
          </Button>
        </div>
      </Grid>

      {/* 메모 조회 기능 */}
      <Grid container className={classes.commentArea}>
        {isVisible && (
          <>
            {/* 채팅 형식으로 조회
            <Grid item xs={12} className={classes.chatModalView}>
              <ChatModalView messages={messages}></ChatModalView>
            </Grid> */}
            <Grid item xs={1}></Grid>
            {/* 번역가 최신메모 조회 */}
            <Grid item xs={4}>
              <div className={classes.root2}>
                From Translator
                <div style={{ display: "flex", height: "100%" }}>
                  <textarea
                    rows={4}
                    cols={100}
                    style={{ width: "100%", marginRight: theme.spacing(1), resize: "none" }} // 여백 추가
                    placeholder={!user.admin ? "Please input comment..." : "(Empty)"}
                    value={commentTranslator}
                    onChange={handleCommentTranslatorChange}
                    disabled={user.admin}
                  />
                  {!user.admin ? (
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                      <Button
                        onClick={handleSaveCommentTranslatorClick}
                        variant="contained"
                        color="primary"
                        disabled={user.admin}
                        style={{ flexGrow: 1 }} // 버튼이 컨테이너 높이에 꽉 차도록 설정
                      >
                        Send
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
            {/* 관리자 최신 메모 조회 */}
            <Grid item xs={4}>
              <div className={classes.root2}>
                From Administrator
                <div style={{ display: "flex", height: "100%" }}>
                  <textarea
                    rows={4}
                    cols={100}
                    style={{ width: "100%", marginRight: theme.spacing(1), resize: "none" }} // 여백 추가
                    placeholder={user.admin ? "Please input comment..." : "(Empty)"}
                    value={commentAdmin}
                    onChange={handleCommentAdminChange}
                    disabled={!user.admin}
                  />
                  {user.admin ? (
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                      <Button
                        onClick={handleSaveCommentAdminClick}
                        variant="contained"
                        color="primary"
                        disabled={!user.admin}
                        style={{ flexGrow: 1 }} // 버튼이 컨테이너 높이에 꽉 차도록 설정
                      >
                        Send
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
          </>
        )}
      </Grid>
      {imageTextTargetList.length > 0 ? (
        <Grid container xs={10} className={classes.textFieldContainer}>
          {imageTextTargetList.map((item: ImageTextTargetManual, index: number) => {
            return (
              <>
                <Grid key={"image-text-target-manual" + index} item xs={4} className={classes.pictureLeft}>
                  {item.image_text_data.length > 0 && (
                    <div ref={elementRef} onLoad={() => setElementLoaded(true)} className={classes.imageSize}>
                      <TransformWrapper
                        // centerOnInit={true}
                        doubleClick={{ mode: "reset" }}
                        initialScale={1}
                        minScale={0.3}
                        maxScale={9}
                        limitToBounds={false}
                        initialPositionX={(size.width - imageSize.width) / 2}
                        initialPositionY={(size.height - imageSize.height) / 2}
                        velocityAnimation={{ disabled: true }}
                      >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <div style={{ position: "relative", width: "100%", height: "100%" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px", // Add space between buttons
                                zIndex: 40, // Ensure the buttons are above other content
                              }}
                            >
                              <ActionButton onClick={() => zoomIn()}>
                                <h5>+</h5>
                              </ActionButton>
                              <ActionButton onClick={() => zoomOut()}>
                                <h5>-</h5>
                              </ActionButton>
                              <ActionButton onClick={() => resetTransform()}>reset</ActionButton>
                            </div>
                            <TransformComponent>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center", // 수평 중앙 정렬
                                  alignItems: "center", // 수직 중앙 정렬
                                }}
                                ref={imageRef}
                              >
                                <img
                                  src={"data:image/png;base64," + item.encoded_image_string}
                                  alt={"image 기다리는 중"}
                                  onLoad={() => setImageLoaded(true)}
                                  style={{ maxHeight: "70vh" }}
                                ></img>
                              </div>
                            </TransformComponent>
                          </div>
                        )}
                      </TransformWrapper>
                    </div>
                  )}
                  {!item.encoded_image_string && <div>{t("image-loading")}</div>}
                </Grid>
                <Grid item xs={8} className={classes.pictureRight}>
                  {item.image_text_data.map((data, subIndex) => {
                    // todo: Guide에서 하나만 선택했는데, 모든 컴포넌트가 refresh 되어버림
                    var lastTwoSentences: string[] = [];

                    if (
                      imageTextTargetList[index].image_text_data[subIndex].target_sentence &&
                      imageTextTargetList[index].image_text_data[subIndex].basetext_history.length > 0 &&
                      projectReOpenHistory
                    ) {
                      let basetext_history = imageTextTargetList[index].image_text_data[subIndex].basetext_history;
                      // 프로젝트 종료시점으로 가장 가까운 1개 추출
                      const latestEntries = basetext_history[0].history_data
                        .filter((historyData) => {
                          const dataTime = new Date(historyData.time_created).getTime();
                          // FIXME : Backend에서 History데이터에 Basetext의 생성 시간을 넣을때까지 유지
                          // const projectTime = new Date(projectReOpenHistory.time_created).getTime();
                          const projectTime = new Date(projectCompleteHistory.time_created).getTime();
                          // FIXME dataTime과 projectTime의 차이가 10분 이내의 데이터만 추출
                          return dataTime < projectTime && projectTime - dataTime < UPDATE_BUFFER_TIME;
                        })
                        .sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime())
                        .slice(0, 1);
                      // 입력문장과 재오픈 이전의 basetext가 다를때
                      if (
                        latestEntries &&
                        latestEntries.length > 0
                        //  &&
                        // imageTextTargetList[index].image_text_data[subIndex].target_sentence !==
                        //   latestEntries[0].history_managed_text
                      ) {
                        lastTwoSentences = [
                          latestEntries[0].history_managed_text,
                          imageTextTargetList[index].image_text_data[subIndex].target_sentence,
                        ];
                      } else {
                        lastTwoSentences = ["", imageTextTargetList[index].image_text_data[subIndex].target_sentence];
                      }

                      lastTwoSentences = lastTwoSentences.map((sentence) =>
                        sentence.includes("##No translation needed##") ? "##No translation needed##" : sentence
                      );
                    }
                    return (
                      <>
                        <div
                          key={subIndex}
                          style={{ backgroundColor: checkTrue(index, subIndex) ? "#ECED3D" : "" }}
                          onClick={() =>
                            handleItemClick(
                              data,
                              index,
                              subIndex,
                              lastTwoSentences,
                              projectReOpenHistory,
                              translateProject?.is_closed
                            )
                          }
                        >
                          <TranslationInputManual
                            _data={data}
                            _index={index}
                            _subIndex={subIndex}
                            _onSaveUnit={onSaveUnit}
                            _onWriteUnit={onWriteUnit}
                            _isFinished={translateProject?.is_closed}
                            setIsEdited={setIsEdited}
                            _lastTwoSentences={lastTwoSentences}
                            _projectReOpenHistory={projectReOpenHistory}
                            _selectedSentence={selectedSentence}
                            _handleTabChange={handleTabChange}
                            // 수정이 불가능한 경우 : 결과지 프로젝트가 아니면서 Basetext가 절대언어인 경우
                            _isReadOnly={
                              subProject?.project_type !== 2 &&
                              data.basetext.length > 0 &&
                              data.basetext[0].is_absolute_text
                            }
                          ></TranslationInputManual>
                        </div>
                      </>
                    );
                  })}
                  {item.image_text_data.length < 1 ? (
                    <div className={classes.info}>
                      <Typography variant="h6">
                        {"<"}
                        {t("guide-no-sentence")}
                        {">"}
                      </Typography>
                      <Typography variant="h6">{t("guide-go-next")}</Typography>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            );
          })}
        </Grid>
      ) : (
        <>
          <Grid container xs={10} className={classes.textFieldContainer}>
            <div className={classes.loadingWrapper}>
              <div className={classes.progressWrapper}>
                <LinearProgress variant="indeterminate" className={classes.linearProgress} />
              </div>

              <Typography variant="h6" className={classes.messageBox}>
                Please wait while loading...
              </Typography>
            </div>
          </Grid>
        </>
      )}
      <Grid item xs={2} className={classes.sideGuide}>
        {/* 왼쪽 부분 (기존 번역 문장 입력 및 참고 문장 제공 부분) */}

        <div className={classes.rootTab}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            classes={{ flexContainer: classes.flexContainer }}
            variant="fullWidth"
          >
            <Tab label={"Trans Info"} value={1} classes={{ root: classes.tabRoot }} />
            <Tab label={"Guide"} value={2} classes={{ root: classes.tabRoot }} />
          </Tabs>
        </div>
        {value === 1 && selectedItem !== INIT_SELECTED_ITEM && (
          <>
            <TranslationGuide
              _selectedItem={selectedItem}
              _setSelectedSentence={setSelectedSentence}
              // 수정이 불가능한 경우 : 결과지 프로젝트가 아니면서 Basetext가 절대언어인 경우
              _isReadOnly={
                translateProject?.project_type !== 2 &&
                selectedItem.newData.basetext.length > 0 &&
                selectedItem.newData.basetext[0].is_absolute_text
              }
            ></TranslationGuide>
          </>
        )}
        {value === 2 && (
          <div className={classes.rightContainer}>
            <div className="markdown-content">
              <div style={{ fontSize: "11px" }}>
                <ReactMarkdown children={t("translationGuide")} />
              </div>
            </div>
          </div>
        )}
      </Grid>
      <SourceTargetMatchingModalContent
        _translateProject={translateProject}
        isOpen={isModalSummaryOpen}
        onClose={() => setIsModalSummaryOpen(false)}
        _changePageNumber={changePageNumber}
      ></SourceTargetMatchingModalContent>
    </Grid>
  );
}
export default TranslatorManualComponent;
