// TODO : util 폴더에서 구분해서 사용

import { NO_TRANSLATION_NEEDED, WHOLE_COUNTRY_COLUMNS } from "./Constants";

// 유효한 String 값인지 체크
export const checkValidString = (value: string | null | undefined): value is string => {
  return typeof value === "string" && value.length > 0;
};
// 길이가 0인 Array도 inValid하다고 판단
export const checkValidArray = <T>(array: T[] | null | undefined): array is T[] => {
  return Array.isArray(array) && array.length > 0;
};
export const checkExcludedSentence = (value: string): value is string => {
  return checkValidString(value) && value.indexOf(NO_TRANSLATION_NEEDED) >= 0;
};
export const removeDuplicates = <T>(arr: T[], key: keyof T): T[] => {
  const map = new Map();
  arr.forEach((item) => map.set(item[key], item));
  return Array.from(map.values());
};
export const getStringByteSize = (str: string) => {
  let size = 0;
  if (str && str.length) {
    // 번역문장이 필요없다고 설정된 문장이라면 Count를 진행하지 않는다
    if (str.indexOf(NO_TRANSLATION_NEEDED) >= 0) {
      return 0;
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);

      if (char <= 0x7f) {
        size += 1;
      } else if (char <= 0x7ff) {
        size += 2;
      } else if (char <= 0xffff) {
        size += 3;
      } else {
        size += 4;
      }
    }
  }
  return size;
};

export const getTitleByField = (field: string) => {
  const column = WHOLE_COUNTRY_COLUMNS.find((column) => column.field === field);
  return column ? column.title : undefined;
};

export const convertISOToStandardFormat = (isoString: string) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getDirectionByLangCode = (langCode: string) => {
  // 언어가 rtl(오른쪽에서 왼쪽)인 언어 목록
  const rtlLanguages = ["AR", "IW"];
  // rtl(오른쪽에서 왼쪽)인지 ltr(왼쪽에서 오른쪽)인지 판단
  return rtlLanguages.includes(langCode) ? "rtl" : "ltr";
};
