import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  TextareaAutosize,
  Theme,
} from "@material-ui/core";
import CheckOutLinedIcon from "@material-ui/icons/CheckOutlined";
import HistoryIcon from "@material-ui/icons/History";
import { ActionButton, CancelButton } from "components/Common/Button";
import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import { SubProjectAtom, TranslateProjectAtom } from "../../../system/atom";

import {
  ImageTextDataManual,
  ImageTextTargetManual,
  ProjectCompleteHistory,
  SelectedSentence,
  TranslateProject,
} from "system/types";

import { t } from "i18next";
import MaterialTable from "material-table";
import React from "react";
import { getTranslationLengthMultiplier, NO_TRANSLATION_NEEDED, TABLEICONS } from "system/Constants";

import { EditOutlined } from "@material-ui/icons";
import DiffTextComponent from "components/Common/DiffTextComponent";
import {
  checkExcludedSentence,
  checkValidArray,
  checkValidString,
  getDirectionByLangCode,
  getStringByteSize,
} from "system/util";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { UserApi } from "system/ApiService";

import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootStyle: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    inputClass: {
      marginTop: "10px",
      marginLeft: "15%",
    },
    picture: {
      position: "relative",
      overflow: "scroll",
      width: "80vh",
      maxHeight: "70vh",
    },
    pictureLeft: {},
    pictureRight: {
      overflow: "scroll",
      width: "80vh",
      maxHeight: "70vh",
    },
    imageSize: {
      overflow: "scroll",
      width: "90%",
      maxHeight: "70vh",
      padding: "30px",
    },
    textArea: {
      width: "100%",
      caretColor: "red",
      "&:focus": {
        outline: "3px solid gray",
      },
      "&[readonly]": {
        backgroundColor: "#F7F7F7", // readonly 상태의 배경색
      },
    },

    disabledStyle: {
      color: "#6c757d", // 회색 텍스트
      backgroundColor: "#e9ecef", // 회색 배경
      borderColor: "#ced4da", // 회색 테두리
    },
    sourceSentencetextArea: {
      width: "100%",
      caretColor: "red",
      "&:focus": {
        outline: "none",
      },
      "&[readonly]": {
        backgroundColor: "#F7F7F7", // readonly 상태의 배경색
      },
      color: "#6c757d", // 회색 텍스트
      backgroundColor: "#e9ecef", // 회색 배경
      borderColor: "#ced4da", // 회색 테두리
    },

    info: {
      textAlign: "center",
    },
    savedSentenceGuide: {
      marginLeft: "20px",
      textAlign: "center",
      color: "gray",
      fontSize: "0.7rem",
    },
    saveButton: {
      float: "right",
      height: "calc(2em+15px)",
      minWidth: "10px",
    },
    editButton: {
      float: "right",
      marginLeft: theme.spacing(1),
      height: "calc(2em+15px)",
      minWidth: "10px",
    },
    containerStyle: {
      display: "flex",
      width: "100%",
    },
    columnStyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "95%",
      marginRight: "10px",
      position: "relative",
    },
    basetextBottom: {
      marginBottom: theme.spacing(1),
    },
    noMarginCheckbox: {
      marginLeft: theme.spacing(0),
    },
    exceptCheckbox: {
      marginRight: theme.spacing(5),
    },
    iconStyle: {
      width: "15px",
      height: "auto",
    },
  })
);

interface TranslationInputManualProps {
  _data: ImageTextDataManual;
  _index: number;
  _subIndex: number;
  _onSaveUnit: (imageTextData: ImageTextDataManual, index: number, subIndex: number, isSaved: boolean) => void;
  _onWriteUnit: (imageTextData: ImageTextDataManual, index: number, subIndex: number) => void;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  _selectedSentence: SelectedSentence;
  _handleTabChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  _isFinished?: boolean;
  _trans?: TranslateProject;
  imageTextTargetList?: ImageTextTargetManual[];
  _projectReOpenHistory?: ProjectCompleteHistory;
  _lastTwoSentences?: string[];
  _isReadOnly?: boolean;
}

function TranslationInputManual({
  _data,
  _index,
  _subIndex,
  setIsEdited,
  _onSaveUnit,
  _onWriteUnit,
  _isFinished,
  _trans,
  _projectReOpenHistory,
  _lastTwoSentences,
  _selectedSentence,
  _handleTabChange,
  _isReadOnly,
}: TranslationInputManualProps): ReactElement {
  const [translateProject] = useRecoilState(TranslateProjectAtom);
  const [subProject] = useRecoilState(SubProjectAtom);

  // 원본/번역 문장 데이터 관리
  const [data, setData] = useState<ImageTextDataManual>(_data);

  // 직전 입력 문장 관리
  const [previousSentence, setPreviousSentence] = useState<string>(_data.target_sentence || "");

  // 텍스트박스 재활성용 토글버튼 상태값 관리
  const [checked, setChecked] = React.useState(
    checkValidString(_data.target_sentence) && checkExcludedSentence(_data.target_sentence)
  );
  // 번역문장 입력칸 비활성화 여부 상태값 관리
  const [isDisableTargetSentence, setIsDisableTargetSentence] = React.useState(checkValidString(_data.target_sentence));

  // Save버튼 클릭여부 상태값 관리
  const [isClickedSaveButton, setIsClickedSaveButton] = React.useState(false);

  // 번역 히스토리 정보
  // const [basetextHistory, setBasetextHistory] = React.useState<BasetextHistory[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);

  // isExcept : 해당 국가에서 원본문장 제외 여부(* 치환 시, 디자인 파일에서 해당 번역문장의 원본 문장을 제거)
  const [isExcept, setIsExcept] = useState<boolean>(
    checkValidString(_data.target_sentence) && checkExcludedSentence(_data.target_sentence)
  );

  // isSameSourceTarget : 원본문장과 번역문장이 같은지 여부 (= 원본문장을 번역문장으로 사용할지 여부)
  const [isSameSourceTarget, setIsSameSourceTarget] = useState<boolean>(
    _data.target_sentence === _data.source_sentence
  );

  // source_text의 byte수 계산
  const sourcetextByte = getStringByteSize(_data.source_sentence);

  //#region Except (해당 국가에서 원본문장 제외 여부) 체크박스 함수
  const handleExceptCheckboxChange = () => {
    if (shouldApplyDisabledStyle) {
      return;
    }
    if (!isExcept) {
      // 제외문장 설정
      if (window.confirm("Are you sure you won't use this sentence?")) {
        if (data.target_sentence) {
          setData((prev) => {
            return { ...prev, target_sentence: NO_TRANSLATION_NEEDED + data.target_sentence };
          });
          _onSaveUnit(
            { ...data, target_sentence: NO_TRANSLATION_NEEDED + data.target_sentence },
            _index,
            _subIndex,
            isDisableTargetSentence
          );
        } else {
          setData((prev) => {
            return { ...prev, target_sentence: NO_TRANSLATION_NEEDED };
          });

          _onSaveUnit(
            {
              ...data,
              target_sentence: NO_TRANSLATION_NEEDED + (data.target_sentence ? data.target_sentence : ""),
            },
            _index,
            _subIndex,
            isDisableTargetSentence
          );
        }
        setIsDisableTargetSentence(true);
        setIsExcept(true);
        setIsClickedSaveButton(true);
        // 원본문장과 번역문장 다름 표시 - 번역하지 않기 때문
        setIsSameSourceTarget(false);
      }
    } else {
      // 제외문장 해제
      if (window.confirm("Do you want to input a translation?")) {
        if (data.target_sentence) {
          setData({ ...data, target_sentence: data.target_sentence.replaceAll(NO_TRANSLATION_NEEDED, "") });

          _onSaveUnit(
            { ...data, target_sentence: data.target_sentence.replaceAll(NO_TRANSLATION_NEEDED, "") },
            _index,
            _subIndex,
            isDisableTargetSentence
          );
        }

        setIsDisableTargetSentence(true);
        setIsClickedSaveButton(true);
        setIsExcept(false);
      }
    }
  };
  //#endregion

  //#region Keep original sentence 체크박스 함수
  const handleKeepOriginalSentenceCheckboxChange = () => {
    if (shouldApplyDisabledStyle) {
      return;
    }

    // 원본문장을 번역문장으로 그대로 사용할지 여부를 파악
    if (window.confirm("Do you really use the original sentence as is in the translation?")) {
      if (isSameSourceTarget) {
        // Props로 받은 원본문장을 번역문장이 같은 경우
        if (data.target_sentence) {
          // 번역문장을 저장
          setData({ ...data, target_sentence: data.target_sentence });
          _onSaveUnit({ ...data, target_sentence: data.target_sentence }, _index, _subIndex, isDisableTargetSentence);
        } else {
          console.log(data, "target_sentence is not exists");
          return;
        }
      } else {
        // Props로 받은 원본문장과 번역문장이 다른 경우
        if (data.source_sentence) {
          setData((prev) => {
            return { ...prev, target_sentence: data.source_sentence };
          });
          _onSaveUnit({ ...data, target_sentence: data.source_sentence }, _index, _subIndex, isDisableTargetSentence);
        } else {
          console.log(data, "source_sentence is not exists");
          return;
        }
      }
      setIsDisableTargetSentence(true);
      setIsClickedSaveButton(true);

      // 원본문장과 번역문장 같음 표시
      setIsSameSourceTarget(true);
    } else {
      // 기존 isSameSourceTarget 상태값 유지
    }
  };
  //#endregion

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const classes = useStyles();

  const toggleDisableTargetSentence = () => {
    setIsDisableTargetSentence((prev) => !prev);
  };

  const onChangeText = function (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const inputValue = event.target.value;
    const inputByteSize = getStringByteSize(inputValue);

    const limitMultiplier = getTranslationLengthMultiplier("EN_EN", translateProject.target_lang_code);

    setPreviousSentence(event.target.value);
    setIsEdited(true);
    setData({ ...data, [event.target.name]: event.target.value });

    // event.target.value가 data.source_sentence가 같은지 여부를 setIsSameSourceTarget으로 설정
    setIsSameSourceTarget(event.target.value === data.source_sentence);

    // 글자수 제한 사용 임시 중지
    // if (inputValue.length < previousSentence.length) {
    // } else if (limitMultiplier && inputByteSize > sourcetextByte * limitMultiplier) {
    //   const limitPercentage = limitMultiplier * 100;
    //   alert(
    //     `Can't input more than ${limitPercentage}% of the original length.\nIf further reduction is not possible, please contact the administrator.`
    //   );
    //   event.preventDefault();
    // } else {
    //   setPreviousSentence(event.target.value);
    //   setIsEdited(true);
    //   setData({ ...data, [event.target.name]: event.target.value });
    // }
  };

  // #region 번역문장 저장(targettext)
  const onSaveUnit = () => {
    if (data && data.target_sentence && data.target_sentence.trim() !== "") {
      if (data.basetext.length > 0 && data.basetext[0].managed_text.indexOf(NO_TRANSLATION_NEEDED) !== -1) {
        // 과거에 사용하지 않음 (won't use this sentence?)으로 선택하였는데 저장할건지 confirm으로 물어보기
        if (
          !window.confirm(
            "Do you want to save the translation?\nThis sentence was previously marked as 'won't use this sentence' in the last translation project."
          )
        ) {
          return;
        }
      }

      console.log(
        checked ? false : data.hasTargetSentence || isDisableTargetSentence,
        checked,
        data.hasTargetSentence,
        isDisableTargetSentence
      );
      const inputByteSize = getStringByteSize(data.target_sentence);
      const limitMultiplier = getTranslationLengthMultiplier("EN_EN", translateProject.target_lang_code);
      if (limitMultiplier && inputByteSize > sourcetextByte * limitMultiplier) {
        alert("Can't input more than this. If further reduction is not possible, please contact the administrator.");
      }
      _onSaveUnit(data, _index, _subIndex, isDisableTargetSentence);
      setIsDisableTargetSentence(true);
      setIsClickedSaveButton(true);
      setIsEdited(false);
    } else {
      alert("Empty or whitespace-only sentences cannot be saved.");
    }
  };
  // #endregion

  // #region 번역문장 입력(targettext)
  const onWriteUnit = () => {
    _onWriteUnit(data, _index, _subIndex);
  };
  // #endregion

  useEffect(() => {
    onWriteUnit();
  }, [data.target_sentence]);

  useEffect(() => {
    // 현재 프로젝트 타입이 결과지가 아니면서 basetext[0]의 is_absolute_text가 true이면 onSaveUnit 호출
    if (
      !checkValidString(_data.target_sentence) &&
      checkValidArray(_data.basetext) &&
      _data.basetext[0].is_absolute_text &&
      _isReadOnly &&
      (translateProject.project_type || subProject.project_type)
    ) {
      _onSaveUnit(
        { ..._data, target_sentence: _data.basetext[0].managed_text },
        _index,
        _subIndex,
        isDisableTargetSentence
      );
    }
  }, [_data.target_sentence]);

  // #region 번역문장 입력칸 초기값 설정
  useEffect(() => {
    // 초반에 basetext 또는 chatGPT가 있다면 target_sentence에 채우고 시작
    if (
      !checkValidString(_data.target_sentence) &&
      checkValidArray(_data.basetext) &&
      checkValidString(_data.basetext[0].managed_text) &&
      // Basetext가 번역 제외문장이라면 번역문장 입력칸에 채워주지 않음
      _data.basetext[0].managed_text.indexOf(NO_TRANSLATION_NEEDED) === -1
    ) {
      setData((prev) => {
        return { ...prev, target_sentence: _data.basetext[0].managed_text };
      });
    } else if (
      !checkValidString(_data.target_sentence) &&
      checkValidArray(_data.chatgpt_text) &&
      checkValidString(_data.chatgpt_text[0].managed_text)
    ) {
      setData((prev) => {
        return { ...prev, target_sentence: _data.chatgpt_text[0].managed_text };
      });
    }
  }, []);
  // #endregion

  const shouldApplyDisabledStyle =
    // 번역문장 입력칸 비활성화 조건
    // (적절한 번역문장 또는 저장버튼이 클릭된 경우) && 번역문장이 비활성화 상태인 경우
    // 또는 제외문장도, 원본문장 그대로 사용도 아니고 번역문장이 비활성화이며 마감되지 않은 경우
    ((checkValidString(_data.target_sentence) || isClickedSaveButton) && isDisableTargetSentence) ||
    (!isExcept && !isSameSourceTarget && isDisableTargetSentence && !_isFinished);

  // Guide에서 선택한 Item 정보에 따른 target_sentence 갱신
  useEffect(() => {
    if (
      _index === _selectedSentence.index &&
      _subIndex === _selectedSentence.subIndex &&
      _selectedSentence.sentence !== "" &&
      !shouldApplyDisabledStyle
    ) {
      setData((prev) => {
        return { ...prev, target_sentence: _selectedSentence.sentence };
      });
    }
  }, [_selectedSentence]);

  const onViewHistory = async () => {
    if (!translateProject) {
      alert("번역 과제 정보를 불러올 수 없습니다.");
      return;
    }

    setModalOpen(!isModalOpen);
  };
  const handleEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!data.target_sentence || data.target_sentence.trim().length === 0) {
        alert("Enter at least 1 character for translation");
        return;
      }
      if (window.confirm("Do you want to save the ongoing translation?")) {
        onSaveUnit();
      }
    }
  };

  const percentage = ((getStringByteSize(data.target_sentence) / sourcetextByte) * 100).toFixed(2);

  // todo : 리오픈 데이터 TranslatorManualComponent로 몰기
  // 재오픈 날짜 포맷팅
  var dateString = _projectReOpenHistory ? _projectReOpenHistory.time_created : new Date();

  var date = new Date(dateString);

  // en-CA 로케일을 사용하여 YYYY-MM-DD 형식으로 변환
  var formattedReOpenDate = date.toLocaleDateString("en-CA");
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  formattedReOpenDate = `${formattedReOpenDate} ${formattedTime}`;

  const textAreaClass = `${classes.textArea} ${shouldApplyDisabledStyle ? classes.disabledStyle : ""}`;

  const getInputColor = () => {
    if (
      checkValidArray(data.basetext) &&
      checkValidString(data.basetext[0].managed_text) &&
      data.target_sentence === data.basetext[0].managed_text &&
      // 번역 제외 Basetext는 표시하지 않으므로 색상도 변경하지 않음
      data.basetext[0].managed_text.indexOf(NO_TRANSLATION_NEEDED) === -1
    ) {
      return _isReadOnly ? "gray" : "blue"; // ChatGPT 문장과 일치하는 경우
    } else if (
      checkValidArray(data.chatgpt_text) &&
      checkValidString(data.chatgpt_text[0].managed_text) &&
      data.target_sentence === data.chatgpt_text[0].managed_text
    ) {
      return "#74AA9C"; // BaseText 문장과 일치하는 경우, chatGPT 초록색
    }
    return "black"; // 둘 다 아닌 경우
  };

  const color = getInputColor();

  const [groupRole, setGroupRole] = useState<number>(0); // 시스템관리자 : 1, 본사 인원 : 2
  const getUserRole = React.useCallback(() => {
    const decoded: any = jwt_decode(UserApi.GetToken());
    setGroupRole(decoded.sub.group_role[0]);
  }, []);

  useEffect(() => {
    getUserRole();
  }, [getUserRole]);

  return (
    <>
      <div
        style={{
          border: _isFinished && data.is_changed ? "3px solid orange" : "none",
        }}
        className={classes.rootStyle}
        onClick={(e) => {
          // 번역 데이터 클릭시, 번역 참고 데이터 탭으로 전환
          _handleTabChange(e, 1);
        }}
      >
        <div className={classes.containerStyle}>
          {
            // #region 원본/번역 문장 입력칸
          }
          <div className={classes.columnStyle}>
            <div className={classes.textArea}>
              <TextareaAutosize
                id="source_sentence"
                name="source_sentence"
                value={data.source_sentence}
                className={classes.sourceSentencetextArea}
                style={{
                  fontWeight: "bold",

                  // 텍스트 정렬 및 방향 설정
                  direction: getDirectionByLangCode(subProject.source_lang_code),
                  textAlign: getDirectionByLangCode(subProject.source_lang_code) === "rtl" ? "right" : "left",
                }}
                required
                readOnly
              ></TextareaAutosize>
            </div>
            {!isExcept ? (
              <div className={classes.textArea}>
                <TextareaAutosize
                  id="target_sentence"
                  name="target_sentence"
                  value={data.target_sentence}
                  onChange={onChangeText}
                  onKeyPress={handleEnterPress}
                  className={textAreaClass}
                  required
                  readOnly={shouldApplyDisabledStyle || _isReadOnly}
                  style={{
                    color: getInputColor(),

                    // 텍스트 정렬 및 방향 설정
                    direction: getDirectionByLangCode(translateProject.target_lang_code),
                    textAlign: getDirectionByLangCode(translateProject.target_lang_code) === "rtl" ? "right" : "left",
                  }}
                ></TextareaAutosize>
              </div>
            ) : (
              <></>
            )}
            {
              // 절대언어로 번역된 문장을 수정할 수 없는 경우
            }
            {_isReadOnly && (
              <div style={{ width: "100%" }}>
                <span style={{ color: "gray", fontSize: "12px", fontWeight: "bold" }}>
                  Standard translation is unmodifiable. Leave a memo for any changes.
                </span>
              </div>
            )}
          </div>
          {
            //#endregion
          }
          {
            // #region 저장 버튼
          }
          {groupRole !== 2 && !_isFinished && !_isReadOnly && (
            <ActionButton
              onClick={onSaveUnit}
              variant="contained"
              color={isDisableTargetSentence ? "secondary" : "primary"}
              size="small"
              disabled={isDisableTargetSentence}
              className={classes.saveButton}
              title={
                "Finish/Check Button:\nAfter entering the text by using DB data, or GPT, you must click ‘Finish button’ for confirmation.Button will turn from red to grey.\n\nWarning:\nNot clicking on the button can cause translations not being temporarily saved on this platform."
              }
            >
              <CheckOutLinedIcon className={classes.iconStyle} />
            </ActionButton>
          )}
          {
            // #endregion
          }
          {
            // #region 수정 버튼
          }
          {/* 배제문장도 아니고, 번역문장 입력칸이 막혀있을때 */}
          {/* {!isExcept && !isUseOrigin && isDisableTargetSentence && !_isFinished ? ( */}
          {groupRole !== 2 && isDisableTargetSentence && !_isFinished && !_isReadOnly && (
            <>
              <ActionButton
                onClick={toggleDisableTargetSentence}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.editButton}
                title={
                  "Edit Button:\nSelect if you wish to make changes to the text already entered by the translator."
                }
              >
                <EditOutlined className={classes.iconStyle} />
              </ActionButton>
            </>
          )}
          {
            // #endregion
          }
          {
            // #region 이력History 버튼
          }
          {_isFinished && (
            <CancelButton
              onClick={
                //toggleModal
                onViewHistory
              }
              variant="contained"
              color="secondary"
              size="small"
              style={{ float: "right" }}
              className={classes.saveButton}
              disabled={
                !checkValidArray(_data.basetext_history) || !checkValidArray(_data.basetext_history[0].history_data)
              }
            >
              <HistoryIcon className={classes.iconStyle}></HistoryIcon>
            </CancelButton>
          )}
          {
            // #endregion
          }
        </div>

        {!_isReadOnly && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "10px",
                height: "100%",
                float: groupRole !== 2 ? "none" : "right",
              }}
            >
              {groupRole !== 2 ? (
                <div style={{ height: "100%" }}>
                  {/* 마감프로젝트가 아닌 경우에만, 저장버튼 및 수정 토글 표시*/}
                  {!_isFinished ? (
                    <>
                      {
                        // #region 원본사용, 배제문장 버튼(HQ 제외)
                      }
                      {!isExcept && !_isFinished && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                // isUseOrigin ||
                                data.target_sentence === data.source_sentence
                              }
                              onChange={handleKeepOriginalSentenceCheckboxChange}
                              color="secondary"
                              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 12 }} />}
                              checkedIcon={<CheckBoxIcon style={{ fontSize: 12 }} />}
                              disabled={shouldApplyDisabledStyle}
                              //groupRole !== 2 && !isExcept && isDisableTargetSentence && !_isFinished
                            />
                          }
                          label={
                            <span style={{ fontSize: "12px", verticalAlign: "middle" }}>Keep original sentence</span>
                          }
                          title={
                            "Maintain original text button:\nIf you plan to use the text in Korean/English without any changes, please select it."
                          }
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isExcept}
                            onChange={handleExceptCheckboxChange}
                            color="secondary"
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 12 }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: 12 }} />}
                            disabled={shouldApplyDisabledStyle}
                          />
                        }
                        label={
                          <span
                            // onClick={(e) => e.preventDefault()}
                            style={{
                              // cursor: "default",
                              fontSize: "12px",
                              fontWeight: isExcept ? "bold" : "normal",
                              verticalAlign: "middle",
                            }}
                            title={
                              "Skip Button (*Caution):\nIn the current country where translation is taking place, if it is not necessary for this text to be displayed, please select: The sentence may be omitted or not displayed as per the headquarters' instructions."
                            }
                          >
                            Not displayed in translator's country
                          </span>
                        }
                      />
                      {
                        // #endregion
                      }
                    </>
                  ) : (
                    <>
                      {
                        // #region 원본사용, 배제문장 버튼(HQ)
                      }
                      {!isExcept && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.target_sentence === data.source_sentence}
                              onChange={handleKeepOriginalSentenceCheckboxChange}
                              color="secondary"
                              icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 12 }} />}
                              checkedIcon={<CheckBoxIcon style={{ fontSize: 12 }} />}
                            />
                          }
                          disabled={true}
                          label={
                            <span onClick={(e) => e.preventDefault()} style={{ cursor: "default", fontSize: "12px" }}>
                              Keep original sentence
                            </span>
                          }
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isExcept}
                            onChange={handleExceptCheckboxChange}
                            color="secondary"
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 12 }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: 12 }} />}
                          />
                        }
                        label={
                          <span
                            onClick={(e) => e.preventDefault()}
                            style={{
                              cursor: "default",
                              fontSize: "12px",
                              fontWeight: isExcept ? "bold" : "normal",
                            }}
                            title={
                              "Skip Button (*Caution):\nIn the current country where translation is taking place, if it is not necessary for this text to be displayed, please select: The sentence may be omitted or not displayed as per the headquarters' instructions."
                            }
                          >
                            Not displayed in translator's country
                          </span>
                        }
                        disabled={true}
                      />
                      {
                        // #endregion
                      }
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}

              {
                // #region 원본과 번역 길이 비교
              }
              {!isExcept && (
                <div
                  title={
                    "Text maximum limit value:\nThe number shown below the Edit Button shows the limit of text type. Number will turn red if you hit the maximum value."
                  }
                  style={{
                    color: parseFloat(percentage) > 130 ? "red" : "black",
                    verticalAlign: "bottom",
                    height: "100%",
                  }}
                >
                  <div>
                    {percentage}% ({getStringByteSize(data.target_sentence)}/{sourcetextByte} Bytes)
                  </div>
                </div>
              )}
              {
                // #endregion
              }
            </div>
          </>
        )}
      </div>

      {
        // #region 재오픈에 의한 변경 상세
      }
      {checkValidArray(_data.basetext_history) && checkValidArray(_data.basetext_history[0].history_data) ? (
        <Dialog open={isModalOpen} onClose={toggleModal} fullWidth maxWidth="lg">
          <DialogTitle>Translation History</DialogTitle>
          <DialogContent>
            <br></br>
            <b>① [ Final ] User-Submitted Text:</b>
            <br></br>
            {_lastTwoSentences ? _lastTwoSentences[1] : ""} <br></br>
            <br></br>
            <b>② Differences :</b>
            <br />
            {_lastTwoSentences && _lastTwoSentences.length >= 2 && _lastTwoSentences[0] !== "" ? (
              <>
                {_lastTwoSentences[0] !== _lastTwoSentences[1] ? (
                  <>
                    <DiffTextComponent beforeText={_lastTwoSentences[0]} afterText={_lastTwoSentences[1]} />
                  </>
                ) : (
                  <>
                    <span style={{ color: "lightgray" }}>(same)</span>
                    <br></br>
                  </>
                )}
              </>
            ) : (
              <>
                <span style={{ color: "lightgray" }}>(No sentence to check difference)</span>
                <br></br>
              </>
            )}
            <br></br>
            <b>③ Pre-Reopen Sentence (as of {formattedReOpenDate}) :</b>
            <br></br>
            {_lastTwoSentences && _lastTwoSentences[0] !== "" ? (
              _lastTwoSentences[0]
            ) : (
              <span style={{ color: "lightgray" }}>(No sentence)</span>
            )}{" "}
            <br></br>
            <br></br>
            <br></br>
          </DialogContent>

          <DialogTitle>Basetext history table</DialogTitle>
          <DialogContent>
            <MaterialTable
              title=""
              icons={TABLEICONS}
              columns={[
                {
                  title: "" + t("history_managed_text"),
                  field: "history_managed_text",
                  cellStyle: { width: "24%" },
                  disableClick: true,
                },
                {
                  title: "" + t("time_created"),
                  field: "time_created",
                  cellStyle: { width: "24%" },
                  disableClick: true,
                  render: (rowData) => {
                    const date = new Date(rowData.time_created);
                    const formattedDate = date.toLocaleDateString("en-CA"); // YYYY-MM-DD
                    const formattedTime = date.toLocaleTimeString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false, // 24시간 형식
                    });
                    return `${formattedDate} ${formattedTime}`; // YYYY-MM-DD HH:mm:ss
                  },
                  defaultSort: "desc",
                },
              ]}
              data={_data.basetext_history[0].history_data || []}
              options={{
                filtering: true,
                pageSize: 5,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
}

export default TranslationInputManual;
