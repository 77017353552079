import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DownloadButton from "components/Common/Button/DownloadExcelButton";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { BaseInfoApi, ProjectApi } from "system/ApiService";
import { useLoadingDispatch } from "system/LoadingContext";
import { SubProjectAtom, TranslateProjectAtom } from "system/atom";
import { Baselanguage, INIT_TRANSLATE_PROJECT, Memo, SourceTargetMatching, TranslateProject } from "system/types";
import { checkValidArray, checkValidString, convertISOToStandardFormat, getTitleByField } from "system/util";
import * as XLSX from "xlsx";
import { ActionButton } from "./Button";

interface SourceTargetMatchingModalContentProps {
  _translateProject: TranslateProject;
  isOpen: boolean;
  onDataCheck: (hasData: boolean) => void;
  onClose: () => void;
}

// 페이지 이동시, 스크롤을 항상 위로 초기화

export default function SourceTargetMatchingModalContent({
  _translateProject,
  isOpen,
  onClose,
  onDataCheck,
}: SourceTargetMatchingModalContentProps) {
  // main, sub 정보도 포함되어있는 translate project
  const [sourceTargetTranslateProject, setSourceTargetTranslateProject] =
    useState<TranslateProject>(INIT_TRANSLATE_PROJECT);

  // 번역문장 Preview를 위한 코드

  const [modalContent, setModalContent] = useState<SourceTargetMatching[]>([]);
  const [modalMemoContent, setModalMemoContent] = useState<RefinedMemo[]>([]);
  const [sortedMemoHistory, setSortedMemoHistory] = useState<SortedMemoHistory[]>([]);

  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);

  const [hasData, setHasData] = useState<boolean>(false);

  const loading = useLoadingDispatch();

  const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const tableRowStyle: React.CSSProperties = {
    borderBottom: "1px solid #ccc",
  };
  const tableSmallCellStyle: React.CSSProperties = {
    padding: "8px",
    border: "1px solid #ccc",
    width: "5%",
  };
  const tableCellStyle: React.CSSProperties = {
    padding: "8px",
    border: "1px solid #ccc",
    width: "45%",
    wordBreak: "break-all",
  };
  const tableCellStyleNoTranslation: React.CSSProperties = {
    background: "lightgray",
    padding: "8px",
    border: "1px solid #ccc",
    width: "45%",
  };
  const modalStyle: React.CSSProperties = {
    position: "fixed",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "1em",
    border: "1px solid black",
    borderRadius: "4px",
    zIndex: 1000,
    width: "60%",
    maxHeight: "80%",
    overflow: "auto",
  };
  const headerStyle: React.CSSProperties = {
    position: "sticky",
    top: -15,
    backgroundColor: "#fff",
    zIndex: 1000,
    overflow: "hidden",
  };

  const theadStyle: React.CSSProperties = {
    position: "sticky",
    zIndex: 500,
    backgroundColor: "#fff",
  };
  const scrollableContentStyle: React.CSSProperties = {
    maxHeight: "80%",
    overflowY: "auto",
  };

  // 코드를 기반으로, 언어 정보 검색
  function matchLanguage(langCode: string, languageData: Baselanguage[]) {
    const matchedLanguage = languageData.find((language: Baselanguage) => {
      return language.lang_code === langCode;
    });

    return matchedLanguage;
  }

  // 여러 번역 문장 후보 중에서 가장 첫번째 문장만 찾아서 반환
  function findOneTranslateSentence(originalData: SourceTargetMatching[]) {
    return originalData.map((entry) => {
      return {
        ...entry,
        target_sentence: entry.target_sentences[0] ? entry.target_sentences[0].target_sentence : "",
      };
    });
  }

  // useEffect(() => {
  // }, [_translateProject]);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      // 메모 존재하는지 여부 판단
      ProjectApi.getWholePagememo(_translateProject.id ? _translateProject.id : 0)
        .then((memoData) => {
          console.log(checkValidArray(memoData.data), memoData);
          if (checkValidArray(memoData.data)) {
            setHasData(true);
            onShowViewAll(_translateProject);
          } else {
            onDataCheck(false);
            onClose();
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    }
    // if (!hasData) {
    //   console.log("no data");
    //   handleClick();
    // }
  }, [isOpen, _translateProject]);

  interface RefinedMemo {
    page_no: number;
    type1?: Memo; // memo_type이 1인 요소
    type2?: Memo; // memo_type이 2인 요소
  }

  const refineMemoData = (data: Memo[]): RefinedMemo[] => {
    const sortedData = [...data].sort((a, b) => {
      if (a.page_no !== b.page_no) {
        return a.page_no - b.page_no;
      }
      return a.memo_type - b.memo_type;
    });

    const resultMap: { [key: number]: RefinedMemo } = {};
    sortedData.forEach((memo) => {
      if (!resultMap[memo.page_no]) {
        resultMap[memo.page_no] = { page_no: memo.page_no };
      }
      if (memo.memo_type === 1) {
        resultMap[memo.page_no].type1 = memo;
      } else if (memo.memo_type === 2) {
        resultMap[memo.page_no].type2 = memo;
      }
    });

    return Object.values(resultMap);
  };

  const refineHistoryData = (data: Memo[]): SortedMemoHistory[] => {
    const sortedData = [...data].sort((a, b) => {
      if (a.page_no !== b.page_no) {
        return a.page_no - b.page_no;
      }
      return a.memo_type - b.memo_type;
    });

    const resultMap: { [key: number]: SortedMemoHistory } = {};
    sortedData.forEach((memo) => {
      if (!resultMap[memo.page_no]) {
        resultMap[memo.page_no] = { page_no: memo.page_no, type1: [], type2: [] };
      }
      if (memo.memo_type === 1) {
        resultMap[memo.page_no].type1?.push(memo);
      } else if (memo.memo_type === 2) {
        resultMap[memo.page_no]?.type2?.push(memo);
      }
    });

    // `type1`과 `type2`를 각각 역순으로 정렬
    Object.values(resultMap).forEach((sortedMemoHistory) => {
      sortedMemoHistory.type1?.sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());
      sortedMemoHistory.type2?.sort((a, b) => new Date(b.time_created).getTime() - new Date(a.time_created).getTime());
    });
    return Object.values(resultMap);
  };

  // 번역문장 Preview 조회를 위한 API
  const onShowViewAll = async (translateProject: TranslateProject) => {
    try {
      if (translateProject && translateProject.id) {
        ProjectApi.getTranslateCompareSourceTargetByProjectCode(translateProject.id)
          .then((res) => {
            // api 수정후에 전체로 바꿈
            ProjectApi.getWholePagememo(translateProject.id ? translateProject.id : 0)
              .then((memoData) => {
                if (!checkValidArray(memoData.data)) {
                  alert("Memo is empty.");

                  onClose(); // 이미 정의된 onClose 함수 호출
                  setModalMemoContent([]);
                  setSourceTargetTranslateProject(INIT_TRANSLATE_PROJECT);
                  setModalContent([]);
                  setHasData(false);

                  return;
                }

                // memoData.data에서 memo_disable false인 데이터만 추려낸다
                memoData.data = memoData.data.filter((memo: Memo) => memo.memo_disable === false);

                BaseInfoApi.GetBaselanguageList()
                  .then((languageData) => {
                    // TODO : 현재 source_sentence, target_sentence의 언어 정보를 가져오기위해서 전체 번역문장쌍을 불러오고 있음 - 비효율
                    console.log(memoData.data);
                    setSourceTargetTranslateProject(() => translateProject);
                    // res.data.sort((a: SourceTargetMatching, b: SourceTargetMatching) => a.page_no - b.page_no);
                    if (checkValidArray(res.data)) {
                      res.data[0].target_sentence_langcode = translateProject.target_lang_code;

                      // 원문/번역언어명 매칭
                      res.data[0].source_sentence_lang_info = matchLanguage(
                        res.data[0].source_sentence_langcode,
                        languageData.data
                      );
                      res.data[0].target_sentence_lang_info = matchLanguage(
                        res.data[0].target_sentence_langcode,
                        languageData.data
                      );
                    }

                    const refinedData = refineMemoData(memoData.data);
                    const refinedHistoryData = refineHistoryData(memoData.data);
                    console.log(refinedData);
                    setModalMemoContent(refinedData);
                    setModalContent(findOneTranslateSentence(res.data));
                    setSortedMemoHistory(refinedHistoryData);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {});
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    } finally {
    }
  };

  // 엑셀에 개요 추가
  const createOverviewWorksheet = (translateProject: TranslateProject) => {
    const today = new Date();
    const formattedDate = `${String(today.getFullYear()).slice(2)}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
    const overview = [
      { "[item]": "Download Date", "[Content]": formattedDate },
      { "[item]": "Product", "[Content]": translateProject.product_code },
      { "[item]": "Project Name", "[Content]": translateProject.project_name_main_project },
      { "[item]": "Category Name", "[Content]": translateProject.project_name_sub_project },
      { "[item]": "Source Language", "[Content]": getTitleByField(translateProject.source_lang_code) },
      { "[item]": "Target Language", "[Content]": getTitleByField(translateProject.target_lang_code) },
      { "[item]": "Is Closed", "[Content]": translateProject.is_closed },
      { "[item]": "Project Status", "[Content]": translateProject.project_status },
    ];

    return XLSX.utils.json_to_sheet(overview);
  };

  // 엑셀에 번역 필요한 항목 추가
  const createTranslationWorksheet = (data: any[]) => {
    // type1과 type2 컬럼을 Admin / Translator 로 변경
    let dataWithModifiedColumnName = data.map((item) => {
      const updatedItem: UpdatedDataType = {
        page_no: item.page_no,
      };

      if (item.type1) {
        updatedItem.Admin = item.type1;
      }

      if (item.type2) {
        updatedItem.Translator = item.type2;
      }

      return updatedItem;
    });
    console.log(dataWithModifiedColumnName);
    return XLSX.utils.json_to_sheet(dataWithModifiedColumnName);
  };

  type OriginalDataType = {
    page_no: number;
    type1?: string;
    type2?: string;
  };

  type UpdatedDataType = {
    page_no: number;
    Admin?: string;
    Translator?: string;
  };

  // 엑셀에 필요한 정보 추가
  const generateCustomWorkbook = () => {
    let filteredData = null;

    const includeColumns = ["page_no", "type1", "type2"];

    filteredData = modalMemoContent.map((entry: any) => {
      const result: { [key: string]: any } = {};

      if (includeColumns) {
        includeColumns.forEach((col) => {
          if (entry[col] !== undefined) {
            result[col] = col === "page_no" ? entry[col] : entry[col].memo_contents;
          }
        });
      } else {
        return entry;
      }
      return result;
    });

    const wb = XLSX.utils.book_new();

    const overviewWs = createOverviewWorksheet(sourceTargetTranslateProject);
    XLSX.utils.book_append_sheet(wb, overviewWs, "Project Overview");

    const translationWs = createTranslationWorksheet(filteredData);
    XLSX.utils.book_append_sheet(wb, translationWs, "Memo");

    return wb;
  };

  const [expandedRows, setExpandedRows] = useState<ExpandedRows>({});

  const toggleHistory = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  type ExpandedRows = {
    [key: number]: boolean;
  };

  interface SortedMemoHistory {
    page_no: number;
    type1?: Memo[]; // memo_type이 1인 요소
    type2?: Memo[]; // memo_type이 2인 요소
  }

  const INIT_SORTED_MEMO_HISTORY: SortedMemoHistory = {
    page_no: 0,
    type1: [], // memo_type이 1인 요소
    type2: [], // memo_type이 2인 요소
  };

  const renderMemoHistory = (historyItem: SortedMemoHistory) => {
    let maxLength;
    if (historyItem.type1 && historyItem.type2) {
      maxLength = Math.max(historyItem.type1.length, historyItem.type2.length);
    } else if (historyItem.type1) {
      maxLength = historyItem.type1.length;
    } else if (historyItem.type2) {
      maxLength = historyItem.type2.length;
    } else {
      maxLength = 0;
    }

    // 더 긴 배열의 길이만큼 렌더링합니다.
    return Array.from({ length: maxLength }).map((_, subIndex) => {
      if (subIndex == 0) {
        return;
      }
      // type1과 type2에서 현재 인덱스의 데이터를 가져옵니다.
      const type1Memo = historyItem.type1 ? historyItem.type1[subIndex] : null;
      const type2Memo = historyItem.type2 ? historyItem.type2[subIndex] : null;

      return (
        <tr>
          <td style={tableSmallCellStyle}></td>
          {type2Memo ? (
            <td style={tableCellStyle}>
              {/* // type2 데이터가 있으면 렌더링합니다. */}
              <span style={{ whiteSpace: "pre-wrap" }}>{type2Memo.memo_contents}</span>
              <br />
              <span style={{ float: "right", color: "darkgray" }}>
                <b>{convertISOToStandardFormat(type2Memo.time_created)}</b>
              </span>
            </td>
          ) : (
            // type2 데이터가 없으면 빈 칸 또는 대체 텍스트를 렌더링합니다.
            <td style={tableCellStyleNoTranslation}></td>
          )}
          {type1Memo ? (
            <td style={tableCellStyle}>
              {/* // type1 데이터가 있으면 렌더링합니다. */}
              <span style={{ whiteSpace: "pre-wrap" }}>{type1Memo.memo_contents}</span>
              <br />
              <span style={{ float: "right", color: "darkgray" }}>
                <b>{convertISOToStandardFormat(type1Memo.time_created)}</b>
              </span>
            </td>
          ) : (
            // type1 데이터가 없으면 빈 칸 또는 대체 텍스트를 렌더링합니다.s
            <td style={tableCellStyleNoTranslation}></td>
          )}
          <td style={tableSmallCellStyle}></td>
        </tr>
      );
    });
  };

  const findSortedMemoHistory = (sortedMemoHistory: SortedMemoHistory[], pageNumber: number) => {
    return sortedMemoHistory.find((history: SortedMemoHistory) => history.page_no === pageNumber);
  };

  const findEmptyHistory = (sortedMemoHistory: SortedMemoHistory[], pageNumber: number): boolean => {
    const pageMemoHistory = sortedMemoHistory.find((history) => history.page_no === pageNumber);
    if (!pageMemoHistory) return false;

    if (pageMemoHistory?.type1) {
      // type1이 배열인지 확인하고, 배열이면 길이가 2 이상인지 확인
      const isType1Array = Array.isArray(pageMemoHistory?.type1);
      return isType1Array && pageMemoHistory.type1.length >= 2;
    } else if (pageMemoHistory?.type2) {
      // type2도 동일한 방식으로 처리
      const isType2Array = Array.isArray(pageMemoHistory?.type2);
      return isType2Array && pageMemoHistory.type2.length >= 2;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 10,
        display: hasData ? "block" : "none",
      }}
      onClick={() => {
        onClose(); // 이미 정의된 onClose 함수 호출
        setModalMemoContent([]);
        setSourceTargetTranslateProject(INIT_TRANSLATE_PROJECT);
        setModalContent([]);
        setHasData(false);
      }}
    >
      {/* <Draggable positionOffset={{ x: "-40%", y: "-45%" }}> */}
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <div style={{ position: "relative" }}>
          <div style={headerStyle}>
            <br />
            <h5>View All Memos in Project</h5>
            <br />
            <DownloadButton
              generateWorkbook={generateCustomWorkbook}
              style={{ position: "absolute", right: "100px", backgroundColor: "lightgray", color: "black" }}
              data={modalContent}
              filename={
                _translateProject &&
                checkValidString(subProject.project_name) &&
                checkValidString(_translateProject.source_lang_code) &&
                checkValidString(_translateProject.target_lang_code)
                  ? "[Memo]" +
                    subProject.project_name +
                    "_(" +
                    getTitleByField(_translateProject.source_lang_code) +
                    "-" +
                    getTitleByField(_translateProject.target_lang_code) +
                    ")"
                  : "data"
              }
            ></DownloadButton>
            <ActionButton
              style={{ position: "absolute", right: "10px" }}
              variant="contained"
              onClick={() => {
                onClose(); // 이미 정의된 onClose 함수 호출
                setSourceTargetTranslateProject(INIT_TRANSLATE_PROJECT);
                setModalMemoContent([]);
                setModalContent([]);
                setHasData(false);
              }}
            >
              X
            </ActionButton>
            <h6>
              <b>{sourceTargetTranslateProject.project_name_main_project}</b>

              {" > "}
              <b>{sourceTargetTranslateProject.project_name_sub_project}</b>
            </h6>
            <h6>
              {getTitleByField(_translateProject.source_lang_code)}
              {" ➡️ "}
              {getTitleByField(_translateProject.target_lang_code)}
            </h6>
            <table style={tableStyle}>
              <thead style={theadStyle}>
                <tr style={tableRowStyle}>
                  <th style={tableSmallCellStyle}>Page</th>
                  {/* <th style={tableCellStyle}>Source Sentence</th>
                  <th style={tableCellStyle}>Primary Translation</th> */}
                  <th style={tableCellStyle}>Translator</th>
                  <th style={tableCellStyle}>Administrator</th>
                  <th style={tableSmallCellStyle}></th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={scrollableContentStyle}>
            <table style={tableStyle}>
              <tbody>
                {modalMemoContent.map((item, index) => (
                  <>
                    <tr key={index} style={tableRowStyle}>
                      <td style={tableSmallCellStyle}>
                        <Link
                          to={{
                            pathname: "/project/translator/" + sourceTargetTranslateProject.id,
                            state: { page: item.page_no },
                          }}
                        >
                          {item.page_no}
                        </Link>
                      </td>

                      {item && item.type2 && checkValidString(item.type2.memo_contents) ? (
                        <td style={tableCellStyle}>
                          <span style={{ whiteSpace: "pre-wrap" }}>{item.type2.memo_contents}</span>
                          <br />
                          <span style={{ float: "right", color: "darkgray" }}>
                            <b>{convertISOToStandardFormat(item.type2.time_created)}</b>
                          </span>
                        </td>
                      ) : (
                        <td style={tableCellStyleNoTranslation}></td>
                      )}
                      {item && item.type1 && checkValidString(item.type1.memo_contents) ? (
                        <td style={tableCellStyle}>
                          <span style={{ whiteSpace: "pre-wrap" }}>{item.type1.memo_contents}</span>
                          <br />
                          <span style={{ float: "right", color: "darkgray" }}>
                            <b>{convertISOToStandardFormat(item.type1.time_created)}</b>
                          </span>
                        </td>
                      ) : (
                        <td style={tableCellStyleNoTranslation}></td>
                      )}
                      <td style={tableSmallCellStyle}>
                        {findEmptyHistory(sortedMemoHistory, item.page_no) && (
                          <span onClick={() => toggleHistory(index)} style={{ cursor: "pointer" }}>
                            {expandedRows[index] ? "🔼" : "🔽"}
                          </span>
                        )}
                      </td>
                    </tr>

                    {expandedRows[index] &&
                      findSortedMemoHistory(sortedMemoHistory, item.page_no) &&
                      renderMemoHistory(
                        findSortedMemoHistory(sortedMemoHistory, item.page_no) ?? INIT_SORTED_MEMO_HISTORY
                      )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Memo is empty.
        </Alert>
      </Snackbar>
    </div>
  );
}
