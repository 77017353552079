import DownloadButton from "components/Common/Button/DownloadExcelButton";
import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { theme } from "style/theme";
import { BaseInfoApi, ProjectApi } from "system/ApiService";
import { NO_TRANSLATION_NEEDED } from "system/Constants";
import { useLoadingDispatch } from "system/LoadingContext";
import { MainProjectAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";
import { Baselanguage, INIT_TRANSLATE_PROJECT, SourceTargetMatching, TranslateProject } from "system/types";
import { checkValidArray, checkValidString } from "system/util";
import * as XLSX from "xlsx";
import { ActionButton } from "./Button";

import { Box, LinearProgress, LinearProgressProps, Typography, makeStyles } from "@material-ui/core";

interface SourceTargetMatchingModalContentProps {
  _translateProject: TranslateProject;
  isOpen: boolean;
  onClose: () => void;
  _changePageNumber?: (page: number) => void;
}

// 페이지 이동시, 스크롤을 항상 위로 초기화

const SourceTargetMatchingModalContent = ({
  _translateProject,
  isOpen,
  onClose,
  _changePageNumber,
}: SourceTargetMatchingModalContentProps) => {
  const useStyles = makeStyles({
    colorPrimary: {
      backgroundColor: "#b2dfdb", // 프로그레스 바 배경 색상 변경 (옵션)
    },
    barColorPrimary: {
      backgroundColor: "#2196f3", // 프로그레스 바 색상을 파란색으로 변경
    },
    root: {
      height: 10,
      borderRadius: 5,
    },
    link: {
      color: "#52b7eb",
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        color: "blue",
        textDecoration: "underline",
      },
    },
  });
  // main, sub 정보도 포함되어있는 translate project
  const [sourceTargetTranslateProject, setSourceTargetTranslateProject] =
    useState<TranslateProject>(INIT_TRANSLATE_PROJECT);

  // 번역문장 Preview를 위한 코드

  const [modalContent, setModalContent] = useState<SourceTargetMatching[]>([]);

  const mainProject = useRecoilValue(MainProjectAtom);
  const subProject = useRecoilValue(SubProjectAtom);
  const translateProject = useRecoilValue(TranslateProjectAtom);

  const loading = useLoadingDispatch();

  const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const tableRowStyle: React.CSSProperties = {
    borderBottom: "1px solid #ccc",
  };
  const tableSmallCellStyle: React.CSSProperties = {
    padding: "8px",
    border: "1px solid #ccc",
    width: "8%",
  };
  const tableCellStyle: React.CSSProperties = {
    padding: "8px",
    border: "1px solid #ccc",
    width: "46%",
  };
  const modalStyle: React.CSSProperties = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "1em",
    border: "1px solid black",
    borderRadius: "4px",
    zIndex: 1000,
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "auto",
  };
  const headerStyle: React.CSSProperties = {
    position: "sticky",
    top: -15,
    backgroundColor: "#fff",
    zIndex: 1000,
    overflow: "hidden",
  };

  const theadStyle: React.CSSProperties = {
    position: "sticky",
    zIndex: 500,
    backgroundColor: "#fff",
  };
  const scrollableContentStyle: React.CSSProperties = {
    maxHeight: "80%",
    overflowY: "auto",
  };

  const tableCellStyleNoTranslationNeeded: React.CSSProperties = {
    background: "lightgray",
    padding: "8px",
    border: "1px solid #ccc",
    width: "46 %",
  };

  const tableCellStyleEmptyTranslation: React.CSSProperties = {
    background: "lightyellow",
    padding: "8px",
    border: "1px solid #ccc",
    width: "46 %",
  };

  const classes = useStyles();

  // 코드를 기반으로, 언어 정보 검색
  function matchLanguage(langCode: string, languageData: Baselanguage[]) {
    const matchedLanguage = languageData.find((language: Baselanguage) => {
      return language.lang_code === langCode;
    });

    return matchedLanguage;
  }

  // 여러 번역 문장 후보 중에서 가장 첫번째 문장만 찾아서 반환
  function findOneTranslateSentence(originalData: SourceTargetMatching[]) {
    return originalData.map((entry) => {
      return {
        ...entry,
        target_sentence: entry.target_sentences[0] ? entry.target_sentences[0].target_sentence : "",
      };
    });
  }

  useEffect(() => {
    onShowViewAll(_translateProject);
  }, [_translateProject]);

  // 번역문장 Preview 조회를 위한 API
  const onShowViewAll = async (translateProject: TranslateProject) => {
    try {
      if (translateProject && translateProject.id) {
        ProjectApi.getTranslateCompareSourceTargetByProjectCode(translateProject.id)
          .then((res) => {
            BaseInfoApi.GetBaselanguageList()
              .then((languageData) => {
                setSourceTargetTranslateProject(() => translateProject);
                res.data.sort((a: SourceTargetMatching, b: SourceTargetMatching) => a.page_no - b.page_no);
                if (checkValidArray(res.data)) {
                  res.data[0].target_sentence_langcode = translateProject.target_lang_code;

                  // 원문/번역언어명 매칭
                  res.data[0].source_sentence_lang_info = matchLanguage(
                    res.data[0].source_sentence_langcode,
                    languageData.data
                  );
                  res.data[0].target_sentence_lang_info = matchLanguage(
                    res.data[0].target_sentence_langcode,
                    languageData.data
                  );
                }
                setModalContent(findOneTranslateSentence(res.data));
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {});
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    } finally {
    }
  };

  // 엑셀에 개요 추가
  const createOverviewWorksheet = (translateProject: TranslateProject, sourceTargetMatching: SourceTargetMatching) => {
    const today = new Date();
    const formattedDate = `${String(today.getFullYear()).slice(2)}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
    const overview = [
      { "[item]": "Download Date", "[Content]": formattedDate },
      { "[item]": "Product", "[Content]": translateProject.product_code },
      { "[item]": "Project Name", "[Content]": translateProject.project_name_main_project },
      { "[item]": "Category Name", "[Content]": translateProject.project_name_sub_project },
      { "[item]": "Source Language", "[Content]": sourceTargetMatching.source_sentence_lang_info?.lang_name_english },
      { "[item]": "Target Language", "[Content]": sourceTargetMatching.target_sentence_lang_info?.lang_name_english },
      { "[item]": "Is Closed", "[Content]": translateProject.is_closed },
      { "[item]": "Project Status", "[Content]": translateProject.project_status },
    ];

    return XLSX.utils.json_to_sheet(overview);
  };

  // 엑셀에 번역 필요한 항목 추가
  const createTranslationWorksheet = (data: any[]) => {
    return XLSX.utils.json_to_sheet(data);
  };

  // 엑셀에 필요한 정보 추가
  const generateCustomWorkbook = () => {
    let filteredData = null;

    const includeColumns = ["page_no", "source_sentence", "target_sentence"];

    filteredData = modalContent.map((entry: any) => {
      const result: { [key: string]: any } = {};
      if (includeColumns) {
        includeColumns.forEach((col) => {
          if (entry[col] !== undefined) {
            result[col] = entry[col];
          }
        });
      } else {
        return entry;
      }
      return result;
    });

    const wb = XLSX.utils.book_new();

    const overviewWs = createOverviewWorksheet(sourceTargetTranslateProject, modalContent[0]);
    XLSX.utils.book_append_sheet(wb, overviewWs, "Project Overview");

    const translationWs = createTranslationWorksheet(filteredData);
    XLSX.utils.book_append_sheet(wb, translationWs, "Translations");

    return wb;
  };

  if (!isOpen) return null;

  const countEmptyTargetSentences = modalContent.filter((item) => item.target_sentence !== "").length;

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary,
              root: classes.root,
            }}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 100,
      }}
      onClick={onClose}
    >
      {/* <Draggable positionOffset={{ x: "-40%", y: "-45%" }}> */}
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <div style={{ position: "relative" }}>
          <div style={headerStyle}>
            <br />
            <h5>View All Translations in Project</h5>
            <br />
            <DownloadButton
              generateWorkbook={generateCustomWorkbook}
              style={{ position: "absolute", right: "100px" }}
              data={modalContent}
              filename={
                translateProject &&
                checkValidArray(modalContent) &&
                checkValidString(sourceTargetTranslateProject.project_name_sub_project) &&
                checkValidString(modalContent[0].source_sentence_lang_info?.lang_code) &&
                checkValidString(modalContent[0].target_sentence_lang_info?.lang_code)
                  ? sourceTargetTranslateProject.project_name_sub_project +
                    "_(" +
                    modalContent[0].source_sentence_lang_info?.lang_code +
                    "-" +
                    modalContent[0].target_sentence_lang_info?.lang_code +
                    ")"
                  : "data"
              }
            ></DownloadButton>
            <ActionButton
              style={{ position: "absolute", right: "10px" }}
              variant="contained"
              onClick={() => {
                setSourceTargetTranslateProject(INIT_TRANSLATE_PROJECT);
                onClose();
              }}
            >
              X
            </ActionButton>
            <h6>
              <b>{sourceTargetTranslateProject.project_name_main_project || mainProject.project_name}</b>
              {" > "}
              <b>{sourceTargetTranslateProject.project_name_sub_project || subProject.project_name}</b>
            </h6>
            <h6>
              {"· "}From :{" "}
              <b>
                {checkValidArray(modalContent) ? modalContent[0].source_sentence_lang_info?.lang_name_english : "-"}{" "}
              </b>
              <img src="/red-background-arrow.png" height="16px" alt="title"></img> To :{" "}
              <b>
                {checkValidArray(modalContent) ? modalContent[0].target_sentence_lang_info?.lang_name_english : "-"}{" "}
              </b>
            </h6>
            <h6>
              {"· "}Progress :
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: theme.spacing(1),
                  display: "inline-block",
                  padding: "8px",
                  // border: "solid 3px #01a2e8",\
                  borderRadius: "5px",
                  backgroundColor: "#2196f3",
                }}
              >
                {" "}
                {countEmptyTargetSentences} / {modalContent.length}{" "}
                {" (" + ((countEmptyTargetSentences * 100) / modalContent.length).toFixed(0) + "%)"}
              </span>
            </h6>
            <LinearProgressWithLabel value={(countEmptyTargetSentences * 100) / modalContent.length} />
            <br />
            <table style={tableStyle}>
              <thead style={theadStyle}>
                <tr style={tableRowStyle}>
                  <th style={tableSmallCellStyle}>Page</th>
                  <th style={tableCellStyle}>Source Sentence</th>
                  <th style={tableCellStyle}>Primary Translation</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={scrollableContentStyle}>
            <table style={tableStyle}>
              <tbody>
                {modalContent.map((item, index) => (
                  <tr key={index} style={tableRowStyle}>
                    <td style={tableSmallCellStyle}>
                      {window.location.pathname === "/project/translator/" + sourceTargetTranslateProject.id ? (
                        <>
                          <a
                            className={classes.link}
                            onClick={() => {
                              if (_changePageNumber) {
                                _changePageNumber(item.page_no);
                              }
                            }}
                          >
                            {item.page_no}
                          </a>
                        </>
                      ) : (
                        <>
                          <Link
                            to={{
                              pathname: "/project/translator/" + sourceTargetTranslateProject.id,
                              state: { page: item.page_no },
                            }}
                          >
                            {item.page_no}
                          </Link>
                        </>
                      )}
                    </td>
                    <td style={tableCellStyle}>{item.source_sentence}</td>
                    <td
                      style={
                        item.target_sentences && item.target_sentences[0]
                          ? item.target_sentences[0].target_sentence.indexOf(NO_TRANSLATION_NEEDED) >= 0
                            ? tableCellStyleNoTranslationNeeded
                            : tableCellStyle
                          : tableCellStyleEmptyTranslation
                      }
                    >
                      {item.target_sentences?.[0] &&
                      item.target_sentences[0].target_sentence.indexOf(NO_TRANSLATION_NEEDED) < 0
                        ? item.target_sentences[0].target_sentence
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
    </div>
  );
};
export default memo(SourceTargetMatchingModalContent);
